import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import IconWechat from 'assets/svg/social/wechat.svg'
import Inview from 'components/Inview'
import ImageWrap from 'components/ImageWrap'
import * as styles from './styles.module.scss'

const Component = (props) => {
  const { data, locale, version, isLeft = false } = props
  const social = data.social.frontmatter[locale]

  const versionClass =
    version === 'dark' ? styles.versionDark : styles.versionLight
  const isLeftClass = isLeft ? styles.isLeft : ''

  return (
    <Inview className={`${versionClass} ${isLeftClass} fade-in up`}>
      <div className={styles.items}>
        {social?.wechat && (
          <div className={styles.item}>
            <div className={styles.icon}>
              <IconWechat />
            </div>
            <div className={styles.qr}>
              <ImageWrap image={social.wechat} />
            </div>
          </div>
        )}
      </div>
    </Inview>
  )
}

export default function Data({ content, version, locale }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          social: markdownRemark(
            frontmatter: { en: { templateKey: { eq: "general-social" } } }
          ) {
            id
            frontmatter {
              en {
                wechat {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              zh {
                wechat {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <Component
          content={content}
          data={data}
          version={version}
          locale={locale}
        />
      )}
    />
  )
}
