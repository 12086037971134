import React from 'react'
import LinkWrap from 'components/LinkWrap'
import LogoSvg from 'assets/svg/vi/logo.svg'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ layoutProps }) => {
  const {
    isMenuOpen,
    // toggleIsMenuOpen,
    // isHeaderUp,
    isHeaderHidden,
    isHeaderBgVersion,
    isHeaderBgVersionWhite,
  } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''
  // const headerUpClass = isHeaderUp ? styles.headerUp : ''
  const headerBgVersionClass = isHeaderBgVersion ? styles.headerBgVersion : ''
  const headerBgVersionWhiteClass = isHeaderBgVersionWhite
    ? styles.headerBgVersionWhite
    : ''
  const headerHiddenClass = isHeaderHidden ? styles.headerHidden : ''
  return (
    <div
      id='header'
      className={`${styles.header} ${headerBgVersionClass} ${headerBgVersionWhiteClass} ${menuOpenClass} `}
    >
      <div className={`${styles.headerHiddenWrap} ${headerHiddenClass}`}>
        <Inview className={`${styles.inner} fade-in stagger-2000`}>
          <div className={styles.bg} />
          <LinkWrap to='/' className={styles.logo}>
            <div className={styles.standard}>
              <LogoSvg />
            </div>
          </LinkWrap>
        </Inview>
      </div>
    </div>
  )
}

export default Component
