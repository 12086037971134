// extracted by mini-css-extract-plugin
export var bg = "styles-module--bg--elhOv";
export var header = "styles-module--header--a+NL6";
export var headerBgVersion = "styles-module--header-bg-version--V-q53";
export var headerBgVersionWhite = "styles-module--header-bg-version-white--bTWD0";
export var headerHidden = "styles-module--header-hidden--XAGkB";
export var headerHiddenWrap = "styles-module--header-hidden-wrap--mgKVK";
export var inner = "styles-module--inner--1joE8";
export var logo = "styles-module--logo--Zb2XA";
export var menuOpen = "styles-module--menu-open--nytUa";
export var standard = "styles-module--standard--+flNT";