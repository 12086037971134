// extracted by mini-css-extract-plugin
export var inner = "styles-module--inner--gxLPs";
export var lan = "styles-module--lan--6EdRc";
export var lanWrap = "styles-module--lan-wrap--HeoJa";
export var logoMobile = "styles-module--logo-mobile--dMxQO";
export var m0 = "styles-module--m0--riBaG";
export var m1 = "styles-module--m1--1Ak5Y";
export var menu = "styles-module--menu--PwICM";
export var menuOpen = "styles-module--menu-open--ht9MN";
export var overlay = "styles-module--overlay--rkdUq";
export var social = "styles-module--social--bNhKx";
export var submenu = "styles-module--submenu--d+kzY";
export var upper = "styles-module--upper--Bv3zd";