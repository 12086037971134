import React, { useState, useEffect, useRef } from 'react'
import { globalHistory } from '@reach/router'
import Header from 'components/Header'
import Menu from 'components/Menu'
import MenuThumb from 'components/MenuThumb'
import * as styles from './styles.module.scss'

const Layout = (props) => {
  const { children, pageContext } = props
  const { locale } = pageContext
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isHeaderUp, setIsHeaderUp] = useState(false)
  const [isHeaderHidden, setIsHeaderHidden] = useState(false)
  const [isHeaderBgVersion, setIsHeaderBgVersion] = useState(false)
  const [isHeaderBgVersionWhite, setIsHeaderBgVersionWhite] = useState(false)
  const [isHomeBannerStarted, setIsHomeBannerStarted] = useState(false)
  const [isHomeBannerOpen, setIsHomeBannerOpen] = useState()

  const toggleIsMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  useEffect(() => {
    setIsHeaderBgVersion(false)
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setIsMenuOpen(false)
        setIsHeaderBgVersion(false)
        setIsHeaderBgVersionWhite(false)
        setIsHeaderHidden(false)
      }
    })
  }, [setIsMenuOpen])

  useEffect(() => {
    // window.addEventListener('scroll', smartMenu, { passive: true })
    return () => {
      // window.removeEventListener('scroll', smartMenu)
    }
  }, [])

  const layoutProps = {
    isMenuOpen,
    toggleIsMenuOpen,
    isHeaderUp,
    isHeaderHidden,
    setIsHeaderHidden,
    isHeaderBgVersion,
    setIsHeaderBgVersion,
    isHeaderBgVersionWhite,
    setIsHeaderBgVersionWhite,
    isHomeBannerStarted,
    setIsHomeBannerStarted,
    isHomeBannerOpen,
    setIsHomeBannerOpen,
  }

  let lockedClass = ''
  if (isHomeBannerOpen === false) lockedClass = styles.locked
  return (
    <div className={`${styles.component} ${lockedClass} lan-${locale}`}>
      <Header layoutProps={layoutProps} />
      <MenuThumb layoutProps={layoutProps} locale={locale} />
      <Menu layoutProps={layoutProps} />
      {React.cloneElement(children, { layoutProps })}
    </div>
  )
}

export default Layout
