import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as styles from './styles.module.scss'

const Component = ({ image, aspectRatio }) => {
  if (typeof image === 'string') {
    return <img src={image} />
  }
  const gImage = getImage(image?.childImageSharp.gatsbyImageData)
  if (gImage) {
    if (aspectRatio) {
      return (
        <div
          className={styles.wrap}
          style={{ paddingBottom: `calc(100% / ${aspectRatio})` }}
        >
          <GatsbyImage image={gImage} alt='' />
        </div>
      )
    } else {
      return <GatsbyImage image={gImage} alt='' />
    }
  }
  return false
}

export default Component
