import React from 'react'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Component = ({ layoutProps, locale }) => {
  const {
    isMenuOpen,
    toggleIsMenuOpen,
    isHeaderBgVersion,
    isHeaderBgVersionWhite,
  } = layoutProps
  const menuOpenClass = isMenuOpen ? styles.menuOpen : ''
  const headerBgVersionClass = isHeaderBgVersion ? styles.headerBgVersion : ''
  const headerBgVersionWhiteClass = isHeaderBgVersionWhite
    ? styles.headerBgVersionWhite
    : ''

  return (
    <Inview
      className={`${styles.thumb} ${menuOpenClass} ${headerBgVersionClass} ${headerBgVersionWhiteClass} fade-in stagger-2000`}
      onClick={toggleIsMenuOpen}
    >
      <span />
      <span />
      <span />
    </Inview>
  )
}

export default Component
