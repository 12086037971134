// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-content-types-page-index-js": () => import("./../../../src/contentTypes/page/index.js" /* webpackChunkName: "component---src-content-types-page-index-js" */),
  "component---src-content-types-partner-index-js": () => import("./../../../src/contentTypes/partner/index.js" /* webpackChunkName: "component---src-content-types-partner-index-js" */),
  "component---src-content-types-project-index-js": () => import("./../../../src/contentTypes/project/index.js" /* webpackChunkName: "component---src-content-types-project-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

